.App {
  text-align: center;
}
p,
li,
ul,
ol,
h1,
h2 {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
.back-home {
  position: absolute;
  z-index: 99;
  
  right: 30px;
  bottom: 30px;
}
.ant-modal {
  border-radius: 20px !important;
}

.ant-modal-content,
.ant-modal-header {
  border-radius: 20px !important;
  background-color: #313131 !important;
}
.ant-modal-header {
  border-bottom: 0 none !important;
}
