.chat-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  padding: 40px;
  padding-bottom: 10vh;
  justify-content: flex-end;
  .chat-wrap {
    width: 28rem;
    max-width: 800px;
    height: 36rem;

    border-radius: 2rem;
    background: #1f1f1f;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    padding: 20px 1.5rem;
    margin-top: 2rem;
    h2 {
      height: 45px;
      line-height: 45px;
    }
    .chat-message-wrap {
      flex-grow: 1;
      overflow-y: auto;
      border-bottom: 1px solid #eee;
      padding-bottom: 2rem;
    }
    .chat-message-box {
      height: 3.75rem;
      margin-top: 2rem;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #606060;
      border-radius: 5.5rem;
      box-sizing: border-box;
      padding: 0.3rem 0.42rem 0.3rem 1.31rem;
      > input {
        background: #606060;
        padding: 5px 20px;
        height: 80%;
        width: 80%;
        color: #ffff;
        resize: none;
      }
    }
  }
  .chat-disabled {
    height: 155px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    flex-shrink: 0;
  }
}
