.whiteboard-container {
  display: grid;
  grid-template-columns: 9fr 3fr;
  height: calc(79vh + 5vh);
  width: 100vw;
  background-color: #f5f5f5;
  padding-top: 20px;
  padding-bottom: 20px;
}

.whiteboard-main {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.whiteboard-main h2 {
  font-size: 18px;
  font-weight: bold;
}

#collabTextBox {
  flex: 1;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  resize: none;
  border: 1px solid #f3f3f3;
  background-color: #f3f3f3;
  margin-top: 10px;
}

.whiteboard-sidebar {
  padding: 15px;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  overflow-y: auto;
  align-items: flex-start;
  justify-content: flex-start;
}

.whiteboard-sidebar h3 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.whiteboard-sidebar ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
  margin: 0;
}

.whiteboard-item {
  background-color: #ecfff5;
  padding: 12px;
  margin-bottom: 5px;
  text-align: center;
  border-radius: 12px;
  transition: background-color 0.5s ease; /* Smooth transition for background color */
}

.whiteboard-sidebar button {
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 8px;
  width: 100%;
  margin-top: 10px;
}

.whiteboard-sidebar button:hover {
  background-color: #218838;
}

.whiteboard-card {
  background-color: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 none;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 90%;
  min-height: 85%;
  margin-bottom: 15px;
}

.whiteboard-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
}

.submit-button:hover {
  background-color: #218838;
}
.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.two-button-container {
  display: flex;
  justify-content: space-between;
  width: 94%;
  padding: 0 20px;
}

.left-button {
  margin-right: auto;
  padding: 2px 30px;
}

.right-button {
  margin-left: auto;
  padding: 10px 20px;
}

.share-button {
  min-width: 120px;
}

.button-container .submit-button:last-child {
  margin-right: 0;
}
