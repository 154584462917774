.viewport {
  position: relative;
  padding: 5vh 0 10vh;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  background-color: #000;
  display: flex;
  overflow-x: hidden;
  .video-container {
    position: relative;
    height: 70vh;
    aspect-ratio: 16/9;
    &.video-container-attech {
      display: flex;
      align-items: center;
    }
    &.video-container-in-sharing {
      width: 264px;
      height: 148px;

      flex-shrink: 0;
    }
    &.single-video-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .single-video-wrap {
      width: 100%;
      aspect-ratio: 16/9;
      height: auto;
      z-index: 100;
      position: relative;
      max-height: 100%;
    }

    .video-canvas {
      width: 100%;
      height: 100%;
    }
    .self-video {
      position: absolute;
      width: 254px;
      height: 143px;
      z-index: 200;
      right: 0;
      bottom: 0;
      display: none;
      &.single-self-video {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
      &.self-video-show {
        display: block;
      }
    }
    .ant-radio-button-wrapper {
      z-index: 1;
    }
    .video-container-wrap {
      width: 100%;
      height: 100%;
    }

    .video-cell {
      min-width: 256px;
      aspect-ratio: 16/9;
      position: relative;
      flex: 1;
      z-index: 200;
      margin: 12px;
      .change-video-resolution {
        position: absolute;
      }
    }
    // .user-video-cell {
    //   flex: 1;
    //   z-index: 300;

    //   height: 20vh;
    //   aspect-ratio: 16/9;
    //   margin: 12px;
    //   .change-video-resolution {
    //     position: absolute;
    //   }
    //   .video-player {
    //     display: block;
    //     position: absolute;

    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //   }
    // }
  }
  .video-player {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .avatar-list {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }
  .video-operations {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .avatar-wrap {
    position: absolute;
    pointer-events: auto;
    list-style: none;
    top: 0;
    left: 0;
  }
  .avatar-wrap-dragging {
    z-index: 10;
  }
  .single-view-avatar {
    top: 0;
    left: 0;
  }
  .self-video-tag {
    display: none;
    position: absolute;
  }
}
