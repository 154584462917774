@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@layer utilities {
  .scrollbar-thin::-webkit-scrollbar {
    width: 6px;
  }

  .scrollbar-thin::-webkit-scrollbar-thumb {
    background-color: rgba(156, 163, 175, 0.5);
    border-radius: 9999px;
  }

  .scrollbar-thin::-webkit-scrollbar-track {
    background-color: rgba(229, 231, 235, 0.3);
  }

  .dark .scrollbar-thin::-webkit-scrollbar-thumb {
    background-color: rgba(156, 163, 175, 0.7);
  }

  .dark .scrollbar-thin::-webkit-scrollbar-track {
    background-color: rgba(75, 85, 99, 0.3);
  }
}
.ant-modal-wrap {
  z-index: 10000 !important;
}
.ant-modal-confirm-warning .ant-modal-body {
  background-color: white !important;
}
.ant-modal-confirm-warning {
  background-color: transparent !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
