/* Preview page styles */

.preview__root {
  flex-direction: column;
  height: fit-content;
  background-color: black;
  width: 100%;
}
.video-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}
.preview-video {
  position: relative;
  width: 850px;
 aspect-ratio: 16/9;
  border: 1px solid #00b152;
  border-radius: 14px;
  overflow: hidden;
  &>video, &>canvas {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #313131;
    display: none;
  }
  & > .preview-video-show{
    display: block;
  background-color: #313131;
  }
}

.video-operations-preview > div{
  display: flex;
  padding: 15px 20px;
  background-color: rgba(0,0,0,0.7);
  border-radius: 14px;
}
.join-button {
  color: rgba(255, 255, 255, 1);
  background: rgba(0, 141, 250, 1);
  border-radius: 14px;
  font-size: 16px;
  height: 3em;
  width: 24em;
  margin: 2em auto;
  cursor: pointer;
  border: none;
  font-stretch: expanded;
  transition: filter 0.15s ease-out;
}

.join-button:hover {
  filter: brightness(110%);
}

/* The "active" pseudo-class MUST come after "hover" */
.join-button:active {
  filter: brightness(80%);
}

.mic-feedback__very-low svg {
  color: green !important;
  opacity: 0.5 !important;
}

.mic-feedback__low svg {
  color: green !important;
  opacity: 0.6;
}

.mic-feedback__medium svg {
  color: green !important;
  opacity: 0.7;
}

.mic-feedback__high svg {
  color: green !important;
  opacity: 0.8;
}

.mic-feedback__very-high svg {
  color: green !important;
  opacity: 0.9;
}

.mic-feedback__max svg {
  color: green !important;
}

.audio-test-wrap {
  width: 800px;
  margin: 0 auto;
  text-align: left;
  h3{
    margin-top: 30px;
  }
  .speaker-action {
    display: flex;
    .speaker-btn {
      width: 160px;
      margin-right: 30px;
    }
    .speaker-list {
      min-width: 300px;
    }
  }
  .speaker-output {
    margin-top: 30px;
    display: flex;
    .speaker-label {
      width: 160px;
      margin-right: 30px;
    }
  }
}
